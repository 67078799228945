<template>
  <container-list-extend title="Formation">
    <template v-slot:title>
      <div class="p-1 rounded-top d-flex flex-row align-items-center flex-grow-1">
        <BaseIcon
          name="circle"
          width="10"
          height="10"
          class="float-right text-success"
          fill="currentColor"
          v-if="vm.vm_active"
          v-tooltip="'VM active'"
        ></BaseIcon>
        <BaseIcon
          name="circle"
          width="10"
          height="10"
          class="float-right text-danger"
          fill="currentColor"
          v-if="!vm.vm_active"
          v-tooltip="'VM inactive'"
        ></BaseIcon>
        <h6 class="m-0 ml-3" style="line-height: 1rem">
          <description-fromList
            :value="vm.vm_type_id"
            :data="data.vmTypes"
            text="Nouvelle visite médicale"
          ></description-fromList>
        </h6>
      </div>
      <div class="text-muted pl-2 pr-2 pl-md-3 pr-md-3 d-flex align-items-center">
        <small>
          <div v-if="vm.vm_date_validite">
            validité :
            {{ $moment(vm.vm_date_validite).format("DD/MM/YYYY") }}
          </div>
        </small>
      </div>
    </template>
    <template v-slot:form>
      <base-form @click="updateVm(vm)" deleteBtn @delete="deleteVm(vm)" :loading="loading">
        <base-form-row row>
          <BaseSelect
            inputText="Type"
            v-model.number="vm.vm_type_id"
            :options="data.vmTypes"
            :errors="feedback.vm_type_id"
          ></BaseSelect>
          <BaseSelect
            inputText="Etat"
            v-model.number="vm.vm_etat_id"
            :options="data.vmEtats"
            :errors="feedback.vm_etat_id"
          ></BaseSelect>
        </base-form-row>
        <base-form-row row>
          <vms-organisme-manager
            v-model="vm.vm_organisme_id"
            class="w-100"
            :errors="feedback.vm_organisme_id"
          ></vms-organisme-manager>
        </base-form-row>
        <base-form-row row>
          <vms-medecin-manager
            v-model="vm.vm_medecin_id"
            class="w-100"
            :errors="feedback.vm_medecin_id"
          ></vms-medecin-manager>
        </base-form-row>
        <base-form-row row>
          <BaseInputDatePicker
            v-model="vm.vm_date"
            inputText="Date de la visite"
            :errors="feedback.vm_date"
            :iconActive="true"
          ></BaseInputDatePicker>
          <BaseInputDatePicker
            v-model="vm.vm_date_validite"
            inputText="Date de validité"
            :errors="feedback.vm_date_validite"
            :iconActive="true"
          ></BaseInputDatePicker>
        </base-form-row>
        <base-form-row row>
          <BaseInput
            v-model="vm.vm_aptitude_spe"
            inputText="Aptitude(s) spécifique(s)"
            :errors="feedback.vm_aptitude_spe"
            :iconActive="true"
          ></BaseInput>
        </base-form-row>
        <base-form-row row>
          <BaseInputArea
            v-model="vm.vm_commentaire"
            text="Commentaire"
            :errors="feedback.vm_commentaire"
            :iconActive="true"
          ></BaseInputArea>
        </base-form-row>
        <div class>
          <uploader
            :id="'document_vm_' + vm.id"
            url="/upload/document"
            file_system="SIRH"
            :file_field="'medical_' + vm.id"
            :file_key="$route.params.id"
          ></uploader>
        </div>
      </base-form>
    </template>
  </container-list-extend>
</template>
<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import { errHandler } from "@/helpers.js";

import DescriptionFromList from "@/components/bases/DescriptionFromList";
import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInput from "@/components/bases/Input.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import BaseSelect from "@/components/bases/Select.vue";
import BaseIcon from "@/components/bases/Icon.vue";
import Uploader from "@/components/bases/Uploader.vue";
import VmsMedecinManager from "@/components/collaborateurs/profil/forms/medical/VmsMedecinManager.vue";
import VmsOrganismeManager from "@/components/collaborateurs/profil/forms/medical/VmsOrganismeManager.vue";

export default {
  components: {
    ContainerListExtend,
    DescriptionFromList,
    BaseInput,
    BaseInputArea,
    BaseSelect,
    BaseInputDatePicker,
    BaseIcon,
    BaseForm,
    BaseFormRow,
    Uploader,
    VmsMedecinManager,
    VmsOrganismeManager,
  },
  data() {
    return {
      feedback: {},
      loading: false,
      tabView: "infos",
      statut: null,
    };
  },
  props: ["vm"],
  computed: {
    ...mapGetters({
      data: "collaborateurs/data",
    }),
  },
  methods: {
    ...mapActions({
      _updateVm: "collaborateurs/updateVm",
      _deleteVm: "collaborateurs/deleteVm",
    }),
    updateVm: function (vm) {
      this.feedback = {};
      this.loading = true;
      this._updateVm(vm)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteVm: function (vm) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null;
      this.feedback = {};
      this.loading = true;
      this._deleteVm(vm)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style lang="css"></style>
