<template>
  <span :class="{'text-primary': !value}">{{ desc }}</span>
</template>
<script>
export default {
  props: {
    data: {
      type: [Array, Object],
      required: true
    },
    value: {
      type: Number,
      default: 0
    },
    text: {
      type: String,
      default: "Fiche vide"
    },
    field: {
      type: String,
      default: "description"
    }
  },
  computed: {
    desc: function() {
      if (this.data) {
        const index = this.data.findIndex(x => x.id === this.value);
        if (this.data[index] && this.data[index][this.field] !== undefined) {
          return this.data[index][this.field];
        } else {
          return this.text;
        }
      }
      return this.text;
    }
  }
};
</script>