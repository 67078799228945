<template>
  <div class="container-list w-100">
    <div ref="showInputButton" class="d-flex justify-content-between align-items-center border rounded p-1 pl-2">
      <description-from-list :value="value" :data="data.vmOrganismes" text="Nouvel organisme"></description-from-list>
      <button-circle
        name="settings"
        size="18"
        v-if="!showDropDown"
        @click="showDropDown = !showDropDown"
      ></button-circle>
      <button-circle name="x" size="18" v-if="showDropDown" @click="showDropDown = !showDropDown"></button-circle>
    </div>
    <div class="invalid-feedback d-block list-unstyled" v-if="errors">
      <li v-for="error in errors" :key="error">{{ error }}</li>
    </div>
    <div ref="dropdown" class="border list shadow rounded p-2 mt-1 mb-1" v-background-3 v-show="showDropDown">
      <input-search
        class="mt-3"
        text="Rechercher..."
        v-model="searchText"
        :showResults="false"
        @reset="searchText = ''"
        modeIos
      ></input-search>
      <div class="w-100">
        <div class="text-primary cursor-pointer">
          <span class="text-right" @click="showInsertForm = true" v-if="!showInsertForm">
            <small>Ajouter un organisme</small>
          </span>
          <span class="text-right" @click="showInsertForm = false" v-if="showInsertForm">
            <small>Retour à la liste</small>
          </span>
          <small>
            <div v-background-1 class="rounded m-1 p-3" v-if="showInsertForm">
              <h6>Ajouter un organisme</h6>
              <base-form-row row>
                <base-input
                  v-model="formlibelle.description"
                  inputText="Description"
                  :errors="feedback.description"
                ></base-input>
              </base-form-row>
              <div class="text-right mt-3">
                <button class="btn btn-primary" @click="insert(formlibelle)">Ajouter</button>
              </div>
            </div>
          </small>
        </div>
      </div>
      <div
        v-for="lib in dataFiltered"
        :key="lib.id"
        class="cursor-pointer rounded pl-1"
        :class="{ 'hover-item': showUpdateFormId !== lib.id }"
      >
        <div class v-if="!showInsertForm">
          <div class="w-100">
            <div class="d-flex justify-content-between align-items-center" @click="select(lib)">
              <div class :class="{ 'text-danger': lib.id === value }">
                <small>{{ lib.description }}</small>
              </div>
              <div class="d-flex">
                <button-circle
                  name="trash-2"
                  size="18"
                  @click="destroy(lib)"
                  v-if="showUpdateFormId == lib.id && lib.id !== value"
                ></button-circle>
                <button-circle
                  name="arrow-down"
                  size="18"
                  @click="showUpdateFormId = lib.id"
                  v-if="showUpdateFormId !== lib.id"
                ></button-circle>
                <button-circle
                  name="arrow-up"
                  size="18"
                  v-if="showUpdateFormId == lib.id"
                  @click="showUpdateFormId = 0"
                ></button-circle>
                <button-circle
                  name="save"
                  size="18"
                  v-if="showUpdateFormId == lib.id"
                  @click="update(lib)"
                ></button-circle>
              </div>
            </div>
            <div v-if="showUpdateFormId === lib.id" v-background-1 class="rounded m-1 p-3">
              <base-input
                v-model="lib.description"
                inputText="Description"
                :errors="feedback.description"
                class="mb-4"
              ></base-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMultiRowFields } from "vuex-map-fields";
import { mapGetters } from "vuex";
import { errHandler } from "@/helpers.js";
import { mapActions } from "vuex";

import BaseInput from "@/components/bases/Input.vue";
import ButtonCircle from "@/components/bases/ButtonCircle.vue";
import InputSearch from "@/components/bases/InputSearch.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import Popper from "popper.js";
import DescriptionFromList from "@/components/bases/DescriptionFromList";

Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false;

export default {
  components: {
    BaseInput,
    ButtonCircle,
    InputSearch,
    BaseFormRow,
    DescriptionFromList,
  },
  data() {
    return {
      showDropDown: false,
      formlibelle: {
        id: 0,
        description: "",
        alert_delais: 0,
        alert_mail: 0,
      },
      feedback: {},
      showUpdateFormId: 0,
      showInsertForm: false,
      searchText: "",
      loadingGet: false,
      loadingPut: false,
      loadingPost: false,
      loadingDelete: false,
      desc: "Choisir un organisme",
    };
  },
  props: {
    value: { type: Number, required: true },
    errors: { type: [Array, Object], required: false },
  },
  methods: {
    ...mapActions({
      getData: "collaborateurs/getData",
    }),
    select: function (lib) {
      this.$emit("input", lib.id);
      this.showDropDown = false;
      this.searchText = "";
    },
    insert: function (data) {
      this.loadingPut = true;
      this.$http
        .put("/vms/organisme", {
          description: data.description,
        })
        .then((response) => {
          const lib = {
            id: response.data.id,
            description: response.data.description,
          };
          this.getData().then(() => this.select(lib));
          this.showInsertForm = false;
        })
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loadingPut = false));
    },
    update: function (data) {
      this.loadingPost = true;
      this.$http
        .post("/vms/organisme", {
          id: data.id,
          description: data.description,
        })
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loadingPost = false));
    },
    destroy: function (data) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null;
      this.loadingDelete = true;
      this.$http
        .delete("/vms/organisme/" + data.id)
        .then(() => {
          let index = this.vmOrganismes.findIndex((x) => x.id === data.id);
          this.vmOrganismes.splice(index, 1);
          this.searchText = "";
          this.showUpdateFormId = 0;
        })
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loadingDelete = false));
    },
  },
  mounted() {
    this.$nextTick(function () {
      var popper = new Popper(this.$refs["showInputButton"], this.$refs["dropdown"], {
        placement: "bottom-start",
      });
      console.log(popper);
    });
  },
  computed: {
    ...mapGetters({
      data: "collaborateurs/data",
    }),
    ...mapMultiRowFields("collaborateurs", ["data.vmOrganismes"]),
    dataFiltered() {
      if (this.searchText) {
        return this.vmOrganismes.filter((lib) => {
          return lib.description.toLowerCase().includes(this.searchText.toLowerCase());
        });
      }
      return this.vmOrganismes;
    },
  },
};
</script>
<style lang="css">
.container-list {
  position: relative;
}
.list {
  z-index: 2200;
  height: 394px;
  overflow: auto;
  min-width: 100%;
}
</style>
