<template>
  <div class="w-100">
    <div class="float-label" style="position: relative" :class="iosClass">
      <select
        :id="id"
        v-bind="$attrs"
        autocomplete="off"
        :class="validationClass + ' ' + colorClass"
        :value="value"
        @input="change($event.target.value)"
      >
        <option :value="null" :disabled="disabled">{{ inputText }}</option>
        <option v-for="option in options" :value="option.id" :key="option.id">
          {{ option[field] }}
        </option>
      </select>
      <label class="label" :class="labelClass" v-if="value != null" :for="id">
        {{ inputText }}
      </label>
      <div class="icon-container">
        <!--BaseIcon name="lock" color="#dc3545" v-if="iconActive && editable" class="ml-1" /-->
        <slot></slot>
      </div>
    </div>
    <div class="invalid-feedback d-block list-unstyled" v-if="errors.length > 0">
      <li v-for="error in errors" :key="error">{{ error }}</li>
    </div>
  </div>
</template>
<script>
export default {
  name: "BaseInput",
  components: {},
  inheritAttrs: false,
  data() {
    return {
      id: null,
    };
  },
  props: {
    inputText: {
      type: String,
      default: "label",
    },
    inputHelp: {
      type: String,
      default: "",
    },
    options: {
      type: [Object, Array],
      default: () => {
        return {};
      },
    },
    value: {
      type: [Number, String],
      default: null,
    },
    errors: {
      type: [Object, Array, String, Number],
      default: () => {
        return [];
      },
    },
    iconActive: {
      type: Boolean,
      default: true,
    },
    darkBackgroundMode: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    field: {
      type: String,
      default: "description",
    },
    modeIOS: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    reset: function () {
      if (this.$attrs.disabled === undefined) {
        this.$emit("input", 0);
      }
    },

    help: function () {
      alert(this.inputHelp);
    },

    change: function (value) {
      if (value === 0 || value === null || value === "") {
        this.$emit("input", null);
      } else {
        this.$emit("input", value);
      }
    },
  },
  mounted() {
    this.id = this._uid;
  },
  computed: {
    validationClass: function () {
      if (this.errors.length > 0 || this.value === null) {
        return "invalid";
      } else {
        return "valid";
      }
    },

    editable: function () {
      if (this.$attrs.disabled === undefined) {
        return false;
      } else {
        return true;
      }
    },

    colorClass: function () {
      if (!this.$store.getters["colors/darkMode"] && !this.darkBackgroundMode) {
        return "text-dark icon-select-dark";
      } else {
        return "text-white icon-select-white";
      }
    },
    labelClass: function () {
      if (!this.$store.getters["colors/darkMode"] && !this.darkBackgroundMode) {
        return "label-dark";
      } else {
        return "label-white";
      }
    },
    iosClass: function () {
      if (this.modeIOS) {
        if (!this.$store.getters["colors/darkMode"]) {
          return "ios ios-white";
        } else {
          return "ios ios-dark";
        }
      } else {
        return "mt-3";
      }
    },
  },
};
</script>
<style lang="css">
select {
  -webkit-appearance: none; /*Removes default chrome and safari style*/
  -moz-appearance: none; /* Removes Default Firefox style*/
  appearance: none;
  background-color: transparent;
  border: 0;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  width: 100%;
  border-radius: 0;
  height: 2rem;
  cursor: pointer;
  outline: 0;
  text-indent: 0.01px; /* Removes default arrow from firefox*/
  text-overflow: ""; /*Removes default arrow from firefox*/ /*My custom style for fonts*/
}
select.invalid {
  color: #757575 !important;
}

.icon-select-dark {
  background: url("data:image/svg+xml;utf8,<svg width='22px' height='22px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;'><path d='M6,9L12,15L18,9' style='fill:none;fill-rule:nonzero;stroke:grey;stroke-width:2px;'/></svg>")
    no-repeat right 0.05rem center/22px 22px;
}

.icon-select-white {
  background: url("data:image/svg+xml;utf8,<svg width='22px' height='22px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;'><path d='M6,9L12,15L18,9' style='fill:none;fill-rule:nonzero;stroke:white;stroke-width:2px;'/></svg>")
    no-repeat right 0.05rem center/22px 22px;
}

/* LABEL TRANSLATION */
.float-label label {
  position: absolute;
  top: calc(60%);
  left: 0;
  opacity: 0;
  transition: all 0.3s ease;
}

/* LABEL TRANSLATION */
.float-label select:not(:placeholder-shown) + label {
  transform: translateY(-34px);
  -webkit-transform: translateY(-34px);
  opacity: 1;
}

/* VALID COLOR - WHITE MODE */
.float-label select:valid + label.label-dark {
  color: #007bff;
}
/* VALID COLOR - DARK MODE */
.float-label select:valid + label.label-white {
  color: #007bff;
}
/* FOCUS COLOR - WHITE MODE */
.float-label select:focus + label.label-dark {
  color: #6f42c1;
}
/* FOCUS COLOR - DARK MODE */
.float-label select:focus + label.label-white {
  color: #eb9d00;
}
/* BORDER BOTTOM COLOR - WHITE MODE */
.float-label select:focus.text-dark {
  border-bottom: solid 1px #6f42c1 !important;
}

/* BORDER BOTTOM COLOR - DARK MODE */
.float-label select:focus.text-white {
  border-bottom: solid 1px #eb9d00 !important;
}

select:invalid {
  border-bottom: solid 1px #dc3545 !important;
}

.text-placeholder {
  color: #a7adb3;
}

select:disabled {
  color: #a7adb3;
}

/* 
* IOS STYLE (WITH PROP)
*/

.float-label.ios select {
  border: none;
}

.float-label.ios {
  border: none;
  border-radius: 5px 5px 5px 5px;
  padding: 0.3rem;
  padding-left: 0.6rem;
}

.ios-dark {
  background-color: #252a2e;
}

.ios-white {
  background-color: #e2e2e2;
}

.float-label.ios select:focus {
  border: none !important;
}

.float-label.ios select:not(:placeholder-shown) + label {
  opacity: 0;
}
</style>
