<template>
  <div class="base-input">
    <div class="float-label" :class="iosClass">
      <input
        ref="inputSearch"
        v-bind="$attrs"
        autocomplete="off"
        type="text"
        :class="validationClass + ' ' + textClass"
        :placeholder="text"
        :aria-label="text"
        :value="value"
        @input="input($event.target.value)"
        @keyup.enter="$emit('search')"
      />
      <label class="label">{{ text }}</label>
      <div class="icon-container d-flex align-items-start mb-1" v-if="showIcon">
        <ButtonCircle
          :loading="loading"
          size="16"
          name="search"
          colorClass="btn-primary"
          @click="$emit('search')"
        ></ButtonCircle>
      </div>
    </div>
    <div class="invalid-feedback d-block list-unstyled" v-if="errors">
      <li v-for="error in errors" :key="error">{{ error }}</li>
    </div>
  </div>
</template>
<script>
import ButtonCircle from "@/components/bases/ButtonCircle.vue";

export default {
  name: "InputSearch",
  components: { ButtonCircle },
  inheritAttrs: false,
  props: {
    text: {
      type: String,
      default: "label",
    },
    value: {
      type: [String, Number],
      default: null,
    },
    errors: {
      type: [Object, Array],
      default: () => {
        return {};
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    darkBackgroundMode: {
      type: Boolean,
      default: false,
    },
    modeIOS: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    reset: function () {
      this.$emit("reset");
    },
    input: function (val) {
      val === "" ? this.$emit("reset") : this.$emit("input", val);
    },
  },
  computed: {
    validationClass: function () {
      if (this.errors) {
        return "invalid";
      } else {
        return "valid";
      }
    },

    textClass: function () {
      if (!this.$store.getters["colors/darkMode"] && !this.darkBackgroundMode) {
        return "text-dark";
      } else {
        return "text-white";
      }
    },

    labelClass: function () {
      if (!this.$store.getters["colors/darkMode"] && !this.darkBackgroundMode) {
        return "label-dark";
      } else {
        return "label-white";
      }
    },

    iosClass: function () {
      if (this.modeIOS) {
        if (!this.$store.getters["colors/darkMode"]) {
          return "ios ios-white mt-2";
        } else {
          return "ios ios-dark mt-2";
        }
      } else {
        return "mt-3";
      }
    },
  },
};
</script>
<style lang="css">
input {
  background-color: transparent;
  font-size: 1rem;
  border: none;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  width: 100%;
  border-radius: 4px 4px 0px 0px;
  height: 2rem;
  padding: 0;
  outline: 0;
  transition: all 0.3s ease;
}

/* LABEL TRANSLATION */
.float-label {
  position: relative;
}

/* LABEL TRANSLATION */
.float-label label {
  position: absolute;
  top: calc(60%);
  left: 0;
  opacity: 0;
  transition: all 0.3s ease;
}

/* LABEL TRANSLATION */
.float-label input:not(:placeholder-shown) + label {
  transform: translateY(-34px);
  -webkit-transform: translateY(-34px);
  opacity: 1;
}

/* VALID COLOR - WHITE MODE */
.float-label input:valid + label.label-dark {
  color: #007bff;
}
/* VALID COLOR - DARK MODE */
.float-label input:valid + label.label-white {
  color: #007bff;
}
/* FOCUS COLOR - WHITE MODE */
.float-label input:focus + label.label-dark {
  color: #6f42c1;
}
/* FOCUS COLOR - DARK MODE */
.float-label input:focus + label.label-white {
  color: #eb9d00;
}
/* BORDER BOTTOM COLOR - WHITE MODE */
.float-label input:focus.text-dark {
  border-bottom: solid 1px #6f42c1 !important;
}
/* BORDER BOTTOM COLOR - DARK MODE */
.float-label input:focus.text-white {
  border-bottom: solid 1px #eb9d00 !important;
}

input:invalid {
  border-bottom: solid 1px #dc3545 !important;
}

input:disabled {
  color: black;
}

/* 
* IOS STYLE (WITH PROP)
*/

.float-label.ios input {
  border: none;
}

.float-label.ios {
  border: none;
  border-radius: 5px 5px 5px 5px;
  padding: 0.3rem;
  padding-left: 0.6rem;
}

.ios-dark {
  background-color: #252a2e;
}

.ios-white {
  background-color: #e2e2e2;
}

.float-label.ios input:focus {
  border: none !important;
}

.float-label.ios input:not(:placeholder-shown) + label {
  opacity: 0;
}
</style>
