<template>
  <div class="w-100">
    <div class="float-label mt-3">
      <Datepicker
        input-class="d-none"
        :calendar-class="textClass + ' mt-1 ' + bgClass"
        :language="fr"
        :monday-first="true"
        :placeholder="inputText"
        v-model="compDateValue"
        :minimumView="minimumView"
        :maximumView="maximumView"
        :ref="id"
      ></Datepicker>
      <base-input
        v-bind="$attrs"
        v-model="compInputDate"
        :inputText="inputText"
        :darkBackgroundMode="darkBackgroundMode"
      ></base-input>
      <div class="icon-container d-flex">
        <div v-if="iconActive && editable">
          <BaseIcon name="lock" color="#dc3545" class="ml-1" />
        </div>
        <!--<div @click="reset" v-if="iconActive && value && !editable">
          <BaseIcon name="x-circle" color="#007bff" class="ml-1" />
        </div>-->
        <div @click="openCalendar" class="cursor-pointer">
          <BaseIcon v-if="iconActive" name="calendar" width="18px" color="#007bff" class="ml-1" />
        </div>
      </div>
    </div>
    <div class="invalid-feedback d-block list-unstyled" v-if="errors">
      <li v-for="error in errors" :key="error">{{ error }}</li>
    </div>
  </div>
</template>
<script>
import BaseIcon from "@/components/bases/Icon.vue";
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import BaseInput from "@/components/bases/Input.vue";

export default {
  name: "BaseInputDatePicker",
  components: { BaseIcon, Datepicker, BaseInput },
  inheritAttrs: false,
  data() {
    return {
      id: null,
      fr: fr
    };
  },
  props: {
    inputText: {
      type: String,
      default: "label"
    },
    value: {
      type: [String, Number, Date],
      default: null
    },
    errors: {
      type: [Object, Array, String, Number],
      default: () => {
        return {};
      }
    },
    iconActive: {
      type: Boolean,
      default: true
    },
    minimumView: {
      type: String,
      required: false
    },
    maximumView: {
      type: String,
      required: false
    },
    darkBackgroundMode: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    reset: function() {
      if (this.$attrs.disabled === undefined) {
        this.$emit("input", null);
      }
    },
    help: function() {
      alert(this.inputHelp);
    },
    setDate: function(e) {
      let dt = this.$moment(e, "DD/MM/YYYY");
      if (e.length === 10) {
        this.$emit("input", dt.format());
      }
      if (e.length === 0) {
        this.$emit("input", null);
      }
    },
    openCalendar: function() {
      this.$refs[this.id].showCalendar();
    }
  },
  mounted() {
    this.id = this._uid;
  },
  computed: {
    validationClass: function() {
      if (this.errors) {
        return "invalid";
      } else {
        return "valid";
      }
    },
    editable: function() {
      if (this.$attrs.disabled === undefined) {
        return false;
      } else {
        return true;
      }
    },

    textClass: function() {
      if (!this.$store.getters["colors/darkMode"]) {
        return "text-dark";
      } else {
        return "text-white";
      }
    },

    labelClass: function() {
      if (!this.$store.getters["colors/darkMode"]) {
        return "label-dark";
      } else {
        return "label-white";
      }
    },

    bgClass: function() {
      if (!this.$store.getters["colors/darkMode"]) {
        return "rounded shadow-sm";
      } else {
        return "rounded bg-dark";
      }
    },
    compDateValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", this.$moment(value).format());
      }
    },
    compInputDate: {
      get() {
        let val = this.$moment(this.value).format("DD/MM/YYYY");
        if (val !== "Invalid date") {
          return val;
        }
        return null;
      },
      set(e) {
        let dt = this.$moment(e, "DD/MM/YYYY");
        if (e.length === 10) {
          this.$emit("input", dt.format());
        }
        if (e.length === 0) {
          this.$emit("input", null);
        }
      }
    }
  }
};
</script>
<style>
input {
  background-color: transparent;
  font-size: 1rem;
  border: none;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  width: 100%;
  border-radius: 4px 4px 0px 0px;
  height: 2rem;
  padding: 0;
  outline: 0;
  transition: all 0.3s ease;
}

/* LABEL TRANSLATION */
.float-label {
  position: relative;
}

/* LABEL TRANSLATION */
.float-label label {
  position: absolute;
  top: calc(60%);
  left: 0;
  opacity: 0;
  transition: all 0.3s ease;
}

/* LABEL TRANSLATION */
.float-label input:not(:placeholder-shown) + label {
  transform: translateY(-34px);
  -webkit-transform: translateY(-34px);
  opacity: 1;
}

/* VALID COLOR - WHITE MODE */
.float-label input:valid + label.label-dark {
  color: #007bff;
}
/* VALID COLOR - DARK MODE */
.float-label input:valid + label.label-white {
  color: #007bff;
}
/* FOCUS COLOR - WHITE MODE */
.float-label input:focus + label.label-dark {
  color: #6f42c1;
}
/* FOCUS COLOR - DARK MODE */
.float-label input:focus + label.label-white {
  color: #eb9d00;
}
/* BORDER BOTTOM COLOR - WHITE MODE */
.float-label input:focus.text-dark {
  border-bottom: solid 1px #6f42c1 !important;
}
/* BORDER BOTTOM COLOR - DARK MODE */
.float-label input:focus.text-white {
  border-bottom: solid 1px #eb9d00 !important;
}

input:invalid {
  border-bottom: solid 1px #dc3545 !important;
}

input:disabled {
  color: black;
}

/* 
* IOS STYLE (WITH PROP)
*/

.float-label.ios input {
  border: none;
}

.float-label.ios {
  border: none;
  border-radius: 5px 5px 5px 5px;
  padding: 0.3rem;
  padding-left: 0.6rem;
}

.ios-dark {
  background-color: #252a2e;
}

.ios-white {
  background-color: #e2e2e2;
}

.float-label.ios input:focus {
  border: none !important;
}

.float-label.ios input:not(:placeholder-shown) + label {
  opacity: 0;
}

.float-label div div label {
  color: #007bff;
}

.cell.day,
.cell.month,
.cell.year {
  border-radius: 0.45rem;
}

.cell.day:hover,
.cell.month:hover,
.cell.year:hover {
  border: 1px Solid #007bff !important;
}

.cell.day.selected,
.cell.month.selected,
.cell.year.selected {
  background: #007bff !important;
  color: #fff;
}

.vdp-datepicker__calendar {
  padding: 0.5rem !important;
}

.vdp-datepicker__calendar header .next,
.vdp-datepicker__calendar header .prev,
.vdp-datepicker__calendar header .up {
  border-radius: 0.45rem;
}

.vdp-datepicker__calendar.bg-dark header .next:after {
  border-left: 10px solid #fff !important;
}

.vdp-datepicker__calendar.bg-dark header .prev:after {
  border-right: 10px solid #fff !important;
}

.vdp-datepicker__calendar.bg-dark header .prev:not(.disabled):hover,
.vdp-datepicker__calendar.bg-dark header .next:not(.disabled):hover,
.vdp-datepicker__calendar.bg-dark header .up:not(.disabled):hover {
  background: #6c757d;
}

.cell.blank,
.cell.blank:hover {
  cursor: auto;
  border: none !important;
}

.vdp-datepicker__calendar .cell.day.today:not(.selected) {
  background: #bcc1c5 !important;
}

.vdp-datepicker__calendar.bg-dark .cell.day.today:not(.selected) {
  background: #6c757d !important;
}
</style>
